<template>
  <div class="content min_box" v-wechat-title="details.land_name?'地块-'+ details.land_name:'详情'">
    <div class="scroll_center">
      <!-- 头部 -->
      <div class="set_margin" id="头部标题-1">
        <div class="ff" id="span1">
          <top :info="{ name: details.land_name }" />
        </div>
      </div>
      <div
        class="st"
        id="轮播图介绍-2"
        v-if="video_img_vr.invest_slide_img && video_img_vr.invest_slide_img.length > 0"
      >
        <!-- 轮播图 -->
        <div class="ff" id="span2">
          <swipers :list="video_img_vr.invest_slide_img" />
        </div>
      </div>
      <!-- 简介 -->
      <div class="set_margin" id="简介-3" v-if="details.land_intro">
        <div class="ff" id="span3">
          <detailType :detail="details.land_intro" :title="'简介'" />
        </div>
      </div>
      <!-- 基本资料 -->
      <div class="set_margin" id="基本资料-4">
        <div class="b_top ff" id="span4">
          <div class="b_top_title">
            <span class="span titles">基本资料</span>
          </div>
          <div class="basicList jianjie">
            <div class="basicItem">
              <span class="left">行政区划</span>
              <span class="span">{{
                details.land_division ? details.land_division : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">项目地址</span>
              <span class="span">{{
                details.land_address ? details.land_address : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">地块编号</span>
              <span class="span">{{
                details.land_number ? details.land_number : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">规划属性</span>
              <span class="span">{{
                details.land_plan_attribute
                  ? details.land_plan_attribute
                  : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">土地性质</span>
              <span class="span">{{
                details.land_plan_nature ? details.land_plan_nature : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">土地面积</span>
              <span class="span">{{
                details.land_area ? details.land_area : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">使用年限</span>
              <span class="span">{{
                details.land_use_years ? details.land_use_years : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">容积率</span>
              <span class="span">{{
                details.land_plot_ratio ? details.land_plot_ratio : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">建筑密度</span>
              <span class="span">{{
                details.land_build_density ? details.land_build_density : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">绿地率</span>
              <span class="span">{{
                details.land_green_rate ? details.land_green_rate : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">建筑高度</span>
              <span class="span">{{
                details.land_building_height
                  ? details.land_building_height
                  : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">四周范围</span>
              <span class="span">{{
                details.land_perimeter ? details.land_perimeter : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">出让时间</span>
              <span class="span">{{
                details.land_transfer_time ? details.land_transfer_time : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">出让价格</span>
              <span class="span">{{
                details.land_transfer_price
                  ? details.land_transfer_price
                  : "暂无"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 规划图 -->
      <div class="set_margin" id="规划图-5" v-if="details.land_plan_img">
        <div class="ff" id="span5">
          <planeGuide :simages="[details.land_plan_img]" />
        </div>
      </div>
      <!-- 视频/音频 -->
      <div
        class="set_margin"
        id="视频-6"
        v-if="video_img_vr.invest_video && video_img_vr.invest_video.length > 0"
      >
        <div class="ff" id="span6">
          <videoaudio :audios="video_img_vr.invest_video" />
        </div>
      </div>
      <!-- VR看景 -->
      <div
        class="set_margin"
        id="VR看景-7"
        v-if="video_img_vr.vrlist && video_img_vr.vrlist.length > 0"
      >
        <div class="ff" id="span7">
          <detailType :title="'VR看景'" :list="video_img_vr.vrlist" />
        </div>
      </div>
      <!-- 导航 -->
      <div
        class="set_margin"
        id="导航-8"
        v-if="details.Latitude && details.Longitude"
      >
        <div class="ff" id="span8">
          <maps :title="'导航'" :info="mapDetail" />
        </div>
      </div>
      <!-- 特色板块 -->
      <!-- 投资详情页必有板块 -->
      <!-- 特色优势 -->
      <div
        class="set_margin"
        id="特色优势-21"
        v-if="details.land_character_advant"
      >
        <div class="ff" id="text21">
          <detail :name="'特色优势'" :detail="details.land_character_advant" />
        </div>
      </div>
      <!-- 周边配套 -->
      <div
        class="set_margin"
        id="周边配套-22"
        v-if="details.land_peri_support"
      >
        <div class="ff" id="text22">
          <detail :name="'周边配套'" :detail="details.land_peri_support" />
        </div>
      </div>
    </div>
    <!-- <scooltop
      @start="start"
      @tosloo="tosloo"
      @totop="totop"
      :listInfo="scorllList"
    /> -->
    <!-- 底部导航栏 -->
    <div class="footer">
      <goodsNav :navBoj="navBoj" v-if="navBoj.phone && navBoj.name" />
    </div>
  </div>
</template>

<script>
import { gardendetail, video_img_vrs } from "@/api/http";
import top from "@/components/investmentCompoent/top.vue";
// import information from '@/components/investmentCompoent/information.vue'
import planeGuide from "@/components/investmentCompoent/planeGuide.vue";
// import scooltop from '@/components/investmentCompoent/scooltop.vue'
import videoaudio from "@/components/investmentCompoent/videoaudio.vue";
import detail from "@/components/investmentCompoent/detail.vue";
import detailType from "@/components/investmentCompoent/detailType.vue";
import maps from "@/components/investmentCompoent/map.vue";
import swipers from "@/components/investmentCompoent/swipers.vue";
import goodsNav from "@/components/investmentCompoent/goodsNav.vue";
export default {
  data() {
    return {
      //高度
      scrollheight: 0,
      //锚点跳转id
      intoindex: "",
      //所有板块名称
      scorllList: [],
      details: {},
      video_img_vr: {},
      mapDetail: {},
      navBoj: {},
      //所有头部表示1,2,3
      arr: [],
      //基本资料
      info: null,
      //类型
      type: "",
      title: "投资详情",
      homeFlag: false,
      params: {},
    };
  },
  components: {
    top,
    // information,
    planeGuide,
    // scooltop,
    videoaudio,
    detail,
    detailType,
    // hotel,
    maps,
    swipers,
    goodsNav,
  },
  methods: {
    //点击目录进行锚点跳转
    tosloo(i) {
      //x轴锚点跳转
      this.$nextTick(() => {
        this.intoindex = "span" + this.arr[i];
      });
    },
    //点击目录回到顶部
    totop() {
      this.intoindex = "span1";
    },
  },
  created() {
    this.params = this.$route.params;
    //轮播图/全景VR/音视频
    video_img_vrs({
      dic_type: 2,
      vr_type: 1,
      id: this.params.id,
    }).then((res) => {
      this.video_img_vr = res.data;
    });
    gardendetail(
      {
        region_code: this.params.code,
        id: this.params.id,
      },
      "landdetail"
    ).then((res) => {
      this.details = res.data.land_detail;
      //地图
      this.mapDetail = {
        lat: this.details.Latitude,
        lng: this.details.Longitude,
        name: this.details.land_address,
      };
      this.navBoj = {
        name: this.details.liaison_man,
        phone: this.details.land_phone,
      };
    });
  },
};
</script>

<style lang="less" scoped>
.content {
  background-color: #eeeeee;
  .scroll_center {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 44px;
    .set_margin {
      overflow: hidden;
      margin: 6px 0;
      padding: 10px;
      background-color: #fff;
      border-radius: 16px;
      font-size: 1rem;
      box-shadow: 0 0 6px rgba(124, 124, 124, 0.2);
    }
  }
}
</style>
